// eslint-disable-next-line import/no-extraneous-dependencies
import {Dropdown} from 'bootstrap';
import Swiper from 'swiper';
import {Navigation, Pagination, Scrollbar} from 'swiper/modules';
import Exhebitions from './plugins/exhebitions.plugin';

window.addEventListener('load', () => {
    Array.from(document.querySelectorAll('.news-swiper')).forEach((elem) => {
        // eslint-disable-next-line no-param-reassign
        elem.Swiper = new Swiper(elem, {
            modules: [Pagination, Navigation],
            loop: true,
            slidesPerView: 1.3,
            spaceBetween: 20,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                576: {
                    slidesPerView: 1.6,
                    spaceBetween: 40,
                },
                960: {
                    slidesPerView: (elem.offsetWidth / (elem.offsetWidth - 280)),
                    spaceBetween: 50,
                },
            },
        });
    });
});

window.addEventListener('load', () => {
    Array.from(document.querySelectorAll('.team-swiper')).forEach((elem) => {
        const scrollbar = elem.closest('.gallery').querySelector('.swiper-scrollbar');

        // eslint-disable-next-line no-param-reassign
        elem.Swiper = new Swiper(elem, {
            modules: [Scrollbar],
            direction: 'horizontal',
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 40,
            scrollbar: {
                el: scrollbar,
                draggable: true,
            },
            mousewheel: true,
        });
    });
});

Array.from(document.querySelectorAll('.dropdown-toggle'))
    .forEach((node) => new Dropdown(node));
Array.from(document.querySelectorAll('.exhebitions'))
    .forEach((node) => new Exhebitions(node));

window.addEventListener('load', () => {
    /**
     * Mobile navigation toggle nav
     */
    const burger = document.querySelector('#hamburger-icon') || false;
    if (burger) {
        burger.addEventListener('click', () => {
            document.body.classList.toggle('open-menu');
        });
    }

    Array.from(document.querySelectorAll('nav a')).forEach((elem) => {
        elem.addEventListener('click', () => {
            document.body.classList.remove('open-menu');
        });
    });

    document.querySelectorAll('[data-target]').forEach((elem) => {
        const t = document.querySelector(elem.dataset.target) || false;

        if (t) {
            elem.addEventListener('click', (e) => {
                e.preventDefault();

                t.classList.toggle('d-none');
                elem.classList.toggle('open');
            });
        }
    });

    const team = document.getElementById('showteam') || false;
    const teamBtn = document.getElementById('showteambtn') || false;

    if (team && teamBtn) {
        teamBtn.addEventListener('click', (e) => {
            e.preventDefault();

            team.classList.toggle('d-none');
            teamBtn.classList.toggle('show');
        });
    }
});

/**
 * Handles dropdown list active on team page.
 */
window.addEventListener('load', () => {
    const filter = document.getElementById('dropdownteamFilter') || false;

    if (filter) {
        const link = filter.closest('div').querySelector(`a[href="${window.location.pathname.substring(1)}"]`) || false;

        if (link) {
            link.classList.add('active');
            filter.innerText = link.innerText;
        }
    }
});
