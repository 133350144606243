export default class Exhebitions {
    constructor(elem) {
        this.elem = elem;
        this.points = [
            ...Array.from(elem.querySelectorAll('.point')),
            ...Array.from(elem.querySelectorAll('.exhebitions-map-text')),
        ];

        this.cards = Array.from(elem.querySelectorAll('.exhebitions-item'));
        this.closeBtns = Array.from(elem.querySelectorAll('.exhebitions-item-close'));

        // eslint-disable-next-line no-shadow
        this.points.forEach((elem) => {
            elem.addEventListener('click', this.clickHandle.bind(this));
        });
        this.closeBtns.forEach((close) => {
            close.addEventListener('click', this.closeHandle.bind(this));
        });

        this.backdrob = document.createElement('div');
        this.backdrob.classList.add('exhebitions-backdrop');

        this.backdrob.addEventListener('click', this.closeHandle.bind(this));

        this.elem.append(this.backdrob);
    }

    closeHandle() {
        this.cards.forEach((item) => {
            item.classList.remove('active');
        });
        this.backdrob.classList.remove('active');
    }

    clickHandle(event) {
        let id = false;

        if (event.currentTarget.id !== '') {
            id = event.currentTarget.id.split('-').pop();
        } else {
            id = event.currentTarget.closest('[data-pos]').dataset.pos;
        }

        if (id) {
            this.cards.forEach((elem) => {
                elem.classList.remove('active');
                this.backdrob.classList.remove('active');
            });

            const card = this.elem.querySelector(`[data-pos="${id}"]`);

            card.classList.add('active');
            this.backdrob.classList.add('active');
        }
    }
}
